import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlockTitle from "./block-title";
import SocialMediaPost from "./social-media-post";

import avatar from "../assets/images/avatar.png";
import dynamicAvatar from "../assets/images/logo.png";

import nature1 from "../assets/images/nature1.jpg";
import nature2 from "../assets/images/nature2.jpg";
import nature3 from "../assets/images/nature3.jpg";

import urban1 from "../assets/images/urban1.jpg";
import urban2 from "../assets/images/urban2.jpg";
import urban3 from "../assets/images/urban3.jpg";

import nightlife1 from "../assets/images/nightlife1.jpg";
import nightlife2 from "../assets/images/nightlife2.jpg";
import nightlife3 from "../assets/images/nightlife3.jpg";

import home1 from "../assets/images/home1.jpg";
import home2 from "../assets/images/home2.jpg";
import home3 from "../assets/images/home3.jpg";

import travel1 from "../assets/images/travel1.jpg";
import travel2 from "../assets/images/travel2.jpg";
import travel3 from "../assets/images/travel3.jpg";

export const SOCIAL_MEDIA_POSTS_CONTEXTS = {NIGHTLIFE: "Nightlife", URBAN: "Urban", NATURE: "Nature", TRAVEL: "Travel", HOME: "Home" };

const SOCIAL_MEDIA_POSTS_NIGHTLIFE = [
    {
        image: nightlife1,
        avatar: avatar,
        username: "Admin",
        caption: "The night's young and so are we. Let's make some memories! 💃🕺 #LateNightFun #UrbanNightlife",
    },
    {
        image: nightlife2,
        avatar: avatar,
        username: "Admin",
        caption: "Stars can't shine without darkness. Neither can we. 🌟 #NightlifeVibes #CityNights",
    },
    {
        image: nightlife3,
        avatar: avatar,
        username: "Admin",
        caption: "When the sun goes down, we dance! 🎶 #PartyAllNight #NightlifeAddict",
    },
];

const SOCIAL_MEDIA_POSTS_URBAN = [
    {
        image: urban1,
        avatar: avatar,
        username: "Admin",
        caption: "Concrete jungle where dreams are made of. 🌆 #CityLights #UrbanJungle  ",
    },
    {
        image: urban2,
        avatar: avatar,
        username: "Admin",
        caption: "City streets, the unofficial runways. 🚶‍♂️🚶‍♀️ #StreetStyle #UrbanFashion",
    },
    {
        image: urban3,
        avatar: avatar,
        username: "Admin",
        caption: "Caught in the city's seductive embrace. 🌃 #CityVibes #UrbanLife",
    },
];

const SOCIAL_MEDIA_POSTS_NATURE = [
    {
        image: nature1,
        avatar: avatar,
        username: "Admin",
        caption: "Embracing the whisper of the rustling leaves. 🍃 #NatureLover #BreatheInTheWild",
    },
    {
        image: nature3,
        avatar: avatar,
        username: "Admin",
        caption: "\"Barefoot on the grass, listening to our favorite song. 🎶🌾 #OneWithNature #EarthingVibes\"",
    },
    {
        image: nature2,
        avatar: avatar,
        username: "Admin",
        caption: "Caught in the serene symphony of twilight. 🌅 #ChasingSunsets #NatureAtItsBest",
    },
];
const SOCIAL_MEDIA_POSTS_TRAVEL = [
    {
        image: travel1,
        avatar: avatar,
        username: "Admin",
        caption: "Travel: The best way to be lost and found at the same time. 🌍 #Wanderlust #AdventureAwaits",
    },
    {
        image: travel2,
        avatar: avatar,
        username: "Admin",
        caption: "Just a wanderer in the foreign lands, seeking stories and memories. 🌏 #WorldExplorer #TravelLife",
    },
    {
        image: travel3,
        avatar: avatar,
        username: "Admin",
        caption: "Travel – because money returns, time doesn’t. ⌛ #PassportLife #ExploreTheWorld",
    },
];

const SOCIAL_MEDIA_POSTS_HOME = [
    {
        image: home1,
        avatar: avatar,
        username: "Admin",
        caption: "Home: A story of who we are and a collection of all the things we love. 🏡 #HomeSweetHome #LivingSpaces",
    },
    {
        image: home2,
        avatar: avatar,
        username: "Admin",
        caption: "A house is made of bricks and beams. A home is made of love and dreams. 💖🏡 #HomeSweetHome #HouseToHome",
    },
    {
        image: home3,
        avatar: avatar,
        username: "Admin",
        caption: "The best journey always leads us home. 🏠 #HomewardBound #LifeAtHome",
    },
];

const socialMediaCarrouselStaticData = (context) => {
    switch (context) {
        case SOCIAL_MEDIA_POSTS_CONTEXTS.NIGHTLIFE:
            return SOCIAL_MEDIA_POSTS_NIGHTLIFE;
        case SOCIAL_MEDIA_POSTS_CONTEXTS.URBAN:
            return SOCIAL_MEDIA_POSTS_URBAN;
        case SOCIAL_MEDIA_POSTS_CONTEXTS.NATURE:
            return SOCIAL_MEDIA_POSTS_NATURE;
        case SOCIAL_MEDIA_POSTS_CONTEXTS.TRAVEL:
            return SOCIAL_MEDIA_POSTS_TRAVEL;
        case SOCIAL_MEDIA_POSTS_CONTEXTS.HOME:
            return SOCIAL_MEDIA_POSTS_HOME;
        default:
            return [];
    }
}

const socialMediaCarrouselDynamicData = (images, captions) => {
    let data = [];

    images.forEach((image, index) => {
       data.push({image: image, avatar: dynamicAvatar, username: 'user', caption: captions[index]});
    });

    return data;
}

const socialMediaCarrouselData = (props) => {
    if (props.socialMediaContext) {
        return socialMediaCarrouselStaticData(props.socialMediaContext);
    }

    return socialMediaCarrouselDynamicData(props.images, props.captions);
}

const socialMediaCarrouselTitleFromContext = (context) => {
    return context;
}

const socialMediaCarrouselTitle = (props) => {
    if (props.socialMediaContext) {
        return socialMediaCarrouselTitleFromContext(props.socialMediaContext);
    }

    return props.title;
};

const SocialMediaCarrousel = (props) => {
    return (
        <section className="blog-style-one" id="social-media-carrousel">
            <Container>
                <BlockTitle
                    textAlign="center"
                    image={avatar}
                    title={socialMediaCarrouselTitle(props)}
                />
                <Row>
                    {socialMediaCarrouselData(props).map(
                        ({ image, avatar, username, caption }, index) => (
                            <Col key={`blog-card-${index}`} lg={5}>
                                <SocialMediaPost
                                    image={image}
                                    username={username}
                                    avatar={avatar}
                                    caption={caption}
                                    className="post-generic"
                                />
                            </Col>
                        )
                    )}
                </Row>
            </Container>
        </section>
    );
};

export default SocialMediaCarrousel;
