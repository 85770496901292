import React from 'react';

const SocialMediaPost = (props) => {
    const avatarURL = props.avatar || undefined;
    const username = props.username || "AlexeSkye";
    const imageURL = props.image || undefined;
    const caption = props.caption || "Amazing views and fun!";
console.log(imageURL);
    return (
        <div className={`instagram-post ${props.className}`}>
            <div className="post-header">
                <img src={avatarURL} alt="avatar" className="avatar"/>
                <h5>{username}</h5>
            </div>
            <img height={498} src={imageURL} alt="post" className="post-image"/>
            <div className="post-footer">
                <p>{caption}</p>
            </div>
        </div>
    )
}

export default SocialMediaPost;
