import React from "react"
import Layout from "../components/layout"
import HeaderOne from "../components/header-one"
import BannerOne from "../components/banner-one"
import FunFact from "../components/funfact"
import Services from "../components/services"
import Pricing from "../components/pricing"
import Faq from "../components/faq"
import Footer from "../components/footer"
import Seo from "../components/seo"
import SocialMediaCarrousel, {SOCIAL_MEDIA_POSTS_CONTEXTS} from "../components/social-media-carrousel";

const Home = () => {
  return (
    <Layout pageTitle="Home">
      <Seo title="Home" />
      <HeaderOne />
      <BannerOne />
      <FunFact />
      <Services />
      <SocialMediaCarrousel socialMediaContext={SOCIAL_MEDIA_POSTS_CONTEXTS.NIGHTLIFE} />
      <SocialMediaCarrousel socialMediaContext={SOCIAL_MEDIA_POSTS_CONTEXTS.URBAN}/>
      <SocialMediaCarrousel socialMediaContext={SOCIAL_MEDIA_POSTS_CONTEXTS.NATURE}/>
      <SocialMediaCarrousel socialMediaContext={SOCIAL_MEDIA_POSTS_CONTEXTS.TRAVEL}/>
      <SocialMediaCarrousel socialMediaContext={SOCIAL_MEDIA_POSTS_CONTEXTS.HOME}/>
      {/*<FeatureTwo />*/}
      {/*<FeatureOne />*/}
      {/*<Testimonials />*/}
      {/*<Brands />*/}
      <Faq />
      <Pricing />
      {/*<Subscribe />*/}
      <Footer />
    </Layout>
  )
}

export default Home
