import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import SocialMediaBouquet from "./social-media-bouquet";

const BannerOne = () => {
  return (
    <section className="banner-style-one" id="banner">
      <span className="bubble-1"></span>
      <span className="bubble-2"></span>
      <span className="bubble-3"></span>
      <span className="bubble-4"></span>
      <span className="bubble-5"></span>
      <span className="bubble-6"></span>
      {/*<img src={moc} className="banner-mock" alt="Awesome alter Text" />*/}
      <SocialMediaBouquet className="banner-mock" />
      <Container>
        <Row>
          <Col xl={6} lg={8}>
            <div className="content-block">
              <h3>
                  <span>Your Personal Social Media Assistant</span>
              </h3>
              <p>
                Like any great personal assistant, Fanster gets to know you and suggests detailed and relevant posts for all your social accounts.
                {/*From general post ideas, to post captions, hashtags and even images featuring you and your friends in different fun activites -- Fanster is with you at every step on your social media journey!*/}
              </p>
              <div className="button-block">
                  <div className="right-side-box">
                      <a href="/access" className="cta-btn">
                          Meet Fanster
                      </a>
                  </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BannerOne
