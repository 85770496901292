import React from 'react';
import SocialMediaPost from './social-media-post';

import avatar from "../assets/images/avatar.png";

import bouquet1 from "../assets/images/bouquet1.jpg";
import bouquet2 from "../assets/images/bouquet2.jpg";
import bouquet3 from "../assets/images/bouquet3.jpg";


const SocialMediaBouquet = (props) => {

    return (
        <div className={`instagram-feed ${props.className}`}>
            <SocialMediaPost caption="Salty air, sun-kissed hair, the endless summer take me there. ☀️🌊 #BeachVibes #SunSeaSand "avatar={avatar} image={bouquet1} className={`post-left`}/>
            <SocialMediaPost caption="Sea more of the world, one beach at a time. 🌎🏖️ #BeachLife #TravelDiaries" avatar={avatar} image={bouquet2} className={`post-center`}/>
            <SocialMediaPost caption="B.E.A.C.H.: Best Escape Anyone Can Have. 🏖️ #BeachEscape #ParadiseFound" avatar={avatar} image={bouquet3} className={`post-right`}/>
        </div>
    );
}

export default SocialMediaBouquet;